.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.subtext {
    text-align: center;
}

p {
    margin: 0;
}

img {
    width: 20rem;
}
